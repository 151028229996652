<template>
  <div class="flex flex-col" :class="$attrs.class">
    <label
      v-if="showlabel"
      :for="name"
      class="block text-sm font-light text-gray-700"
    >
      {{ labelText }}
    </label>
    <div :class="[showlabel ? 'mt-2' : '']">
      <div class="relative h-10">
        <input
          :class="[
            'absolute w-full h-full rounded-md shadow-sm text-sm border-1 focus:ring-primary border-none ring-1 focus:ring-2 focus:outline-none',
            alignOptionsRight ? '' : 'pl-24',
            errorMessage.length > 0 ? 'ring-error' : 'ring-bordercolor',
            $attrs.inputclass,
            { 'bg-gray-100': disabled },
          ]"
          :id="id"
          :name="name"
          :type="type"
          :autocomplete="autocomplete"
          :disabled="disabled || loading"
          :required="required"
          :maxlength="maxLength"
          :placeholder="placeholder"
          :value="modelValue"
          :step="step"
          @input="inputChangedEvent"
          @blur="onBlur"
          @focus="onFocus"
          :readonly="readonly"
        />
        <select
          v-if="isInputSelect"
          :class="[
            'absolute rounded-l-md outline-none border-none focus:ring-0 h-full  transition ease-in-out duration-150 w-22 text-sm md:leading-5',
            alignOptionsRight ? 'right-2 text-gray-400 w-26' : 'bg-gray-100 text-left',
          ]"
          @change="selectedChangedEvent($event)"
          :disabled="disabled || loading"
        >
          <option v-for="option in options" :key="option.id" :value="option.id">
            {{ option.desc }}
          </option>
        </select>
        <div v-else class="bg-gray-100 right-0 absolute h-full w-10  text-center items-center">
          <p class="text-sm pt-2">{{ optionDesc ?? '-' }}</p>
        </div>
      </div>
      <span v-if="errorMessage.length > 0" class="text-xs text-error">{{
        errorMessage[0]
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["onblur", "onfocus"],
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    type: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
    labelText: {
      type: String,
      default: "",
    },
    showlabel: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: [Number, String],
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    option: {
      type: [Number, String],
      default: 0,
    },
    alignOptionsRight: {
      type: Boolean,
      default: false,
    },
    isInputSelect: {
      type: Boolean,
      default: true,
    },
    optionDesc: {
      type: String,
      default: null,
    },
    step: {
      type: String,
    },
    maxLength: {
      default: 0,
    },
  },

  methods: {
    selectedChangedEvent(event) {
      const selectedOption = this.options.find(
        (option) => option.id == event.target.value
      );
      this.$emit("update:option", selectedOption.desc);
    },
    inputChangedEvent(event) {
      var input = event.target.value;

      if (
        input != "" &&
        this.maxLength > 0 &&
        input.length > this.maxLength &&
        this.countDecimals(input) == 0
      ) {
        input = input.slice(0, this.maxLength);
      }

      this.$emit("update:modelValue", input);
    },
    onBlur(event) {
      // convert number to decimal
      if (
        this.type == "number" &&
        event.target.value != "" &&
        this.countDecimals(this.step) > 0
      ) {
        const input = event.target.value;

        let inputToFloat = parseFloat(input);
        let stepToFloat = parseFloat(this.step);

        const inputCountDecimals = this.countDecimals(inputToFloat);
        const stepCountDecimals = this.countDecimals(stepToFloat);

        if (inputCountDecimals > 0 && inputCountDecimals != stepCountDecimals) {
          inputToFloat = inputToFloat.toFixed(stepCountDecimals);
        }

        this.$emit("update:modelValue", inputToFloat);
      }

      this.$emit("onblur", event);
    },
    onFocus(event) {
      this.$emit("onfocus", event);
    },
    countDecimals(input) {
      let value = parseFloat(input);
      if (Math.floor(value) === value) return 0;
      return value.toString().split(".")[1].length || 0;
    },
  },
};
</script>

<style scoped>
/* hide indicator for input number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>





