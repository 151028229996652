<template>
  <router-view />
  <app-alert />
</template>
<script>
import ROLE from "@/utils/const/role";

export default {
  beforeCreate() {
    this.$store.dispatch("authStore/checkAuth");
  },
  computed: {
    email_not_verify_error() {
      return this.$store.getters.email_not_verify_error;
    },
    error_message() {
      return this.$store.getters.error_message;
    },
    user() {
      return this.$store.getters["authStore/user"];
    },
    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },
  },
  watch: {
    email_not_verify_error: {
      handler(error) {
        if (!this.$lodash.isEmpty(error)) {
          this.$notify(
            {
              group: "error",
              title: error,
            },
            5000
          );
        }
      },
      deep: true,
    },
    error_message: {
      handler(errorMessage) {
        if (!this.$lodash.isEmpty(errorMessage)) {
          this.$notify(
            {
              group: "error",
              title: errorMessage,
            },
            5000
          );
        }
      },
      deep: true,
    },
    $route(to, from) {},
  },
};
</script>
