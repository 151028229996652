const colors = require('tailwindcss/colors')
const path = require('path');

module.exports = {
  purge: [
    './src/**/*.vue',
    path.resolve(__dirname, './node_modules/litepie-datepicker/**/*.js'),
  ],
  darkMode: false,
  theme: {
    fontSize: {
      'xxs': '.50rem',
      'xs': '.75rem',
      'sm': '.875rem',
      'slightly-sm': '.94rem',
      'lg': '1.125rem',
      'xl': '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '4rem',
      '7xl': '5rem',
    },
    extend: {
      spacing: {
        '22': '5.5rem'
      },
      height: {
        '34': '8.438rem',
        '26': '6.25rem',
        '90': '22rem',
      },
      width: {
        '26': '6.25rem',
        '90': '22rem',
      },
      fontFamily: {
        'nunito': ['Nunito', 'Arial'],
      },
      colors: {
        'primary': '#BD5927',
        'primary-100': '#E6D38E',
        'primary-200': '#DAB16A',
        'primary-300': '#D39D59',
        'primary-400': '#C57137',
        'primary-500': '#BD5927',
        'primary-600': '#9A4813',
        'primary-700': '#703505',
        'primary-800': '#3F2000',
        'primary-900': '#070400',

        'background': '#FAFAFA',
        'test-mode': '#BD5927',

        'error': '#C91C31',
        'success': '#55BE64',
        'bordercolor': '#9A4813',
        'hover-button': '#DAB16A',
        'click-button': '#E0C37C',

        'success-badge': "#F2FFF4",
        'error-badge': "#FFEEF0",
        'warning-badge': "#FFF2DD",
        'light-badge': "#F5F5F5",

        'white': colors.white,
        'blue': colors.blue,
        'gray': colors.gray,
        'yellow': colors.yellow,
        'red': colors.red,
        'bluelink': '#5c8de1',
        'divider-gray': '#E5E7EB',

        'approve': '#16A34A',
        'decline': '#B91C1C',

        'litepie-primary': {
          100: '#E6D38E',
          200: '#DAB16A',
          300: '#D39D59',
          400: '#C57137',
          500: '#BD5927',
          600: '#9A4813',
          700: '#703505',
          800: '#3F2000',
          900: '#070400',
        }, // color system for light mode
        'litepie-secondary': colors.gray // color system for dark mode
      },
      backgroundImage: theme => ({
        'app-logo': "url('../image/bg_app_logo.svg')",
      })
    },
  },
  variants: {
    extend: {
      opacity: ['disabled'],
      backgroundColor: ['active'],
      padding: ['hover'],
    },
  },
  plugins: [require('@tailwindcss/forms')],
}
