<template>
  <div>
    <div>
      <div class="flex space-x-2 px-5 py-3">
        <p class="text-md font-bold antialiased">
          {{ $t("notification.title") }}
        </p>
        <p class="text-xs px-2 py-1 bg-primary text-white rounded">
          {{ notificationUnread }} {{ $t("notification.unread") }}
        </p>
      </div>
      <div
        class="
          flex
          px-5
          py-2
          justify-between
          border-b border-t
          place-items-center
          text-gray-500 text-sm
        "
      >
        <span class="flex space-x-3">
          <p
            class="hover:text-primary cursor-pointer capitalize"
            v-for="tab in TAB_NOTIFICATION"
            :key="tab.key"
            :class="{
              'text-primary': tab.key == tabNotification,
            }"
            @click="notificationChanges(tab.key)"
          >
            {{ tab.title }}
          </p>
        </span>
        <span>
          <p
            class="hover:text-primary cursor-pointer"
            @click="markAsReadAllNotification"
            v-if="notificationUnread > 0"
          >
            {{ $t("notification.mark_all_as_read") }}
          </p>
        </span>
      </div>
      <div
        v-for="notification in notificationList?.slice(
          0,
          limit ?? notificationList?.length
        )"
        :key="notification"
        class="
          px-5
          py-3
          border-b
          space-y-1
          antialiased
          flex flex-col
          cursor-pointer
          hover:bg-gray-200
        "
        :class="[
          notification.read_at ? 'bg-white' : 'bg-gray-100',
          this.$route.params.notification_id == notification.id
            ? 'hover:bg-primary bg-primary text-white'
            : '',
        ]"
        @click="selectNotification(notification.id)"
      >
        <p class="text-xs">
          {{ $moment(notification.created_at).format("DD MMM YYYY hh:mm a") }}
        </p>
        <p class="text-sm font-medium truncate">
          {{ notification.data?.title }}
        </p>
        <p class="text-sm truncate">
          {{ notification.data?.message }}
        </p>
      </div>
    </div>
    <infinite-loading @infinite="fetchNotifications" :identifier="infiniteId">
      <template v-slot:spinner>{{ $t("general.loading") }}</template>
      <template v-slot:no-results>{{ $t("general.no_results") }}</template>
      <template v-slot:no-more>
        <p v-if="limit"></p>
        <p v-else class="text-sm p-3">{{ $t("general.no_more_results") }}</p>
      </template>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";

export default {
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      page: 1,
      infiniteId: 0,
      tabNotification: "",
      notificationList: [],
      TAB_NOTIFICATION: {
        all: {
          key: "all",
          title: this.$t("notification.all"),
        },
        unread: {
          key: "unread",
          title: this.$t("notification.unread"),
        },
      },
    };
  },
  props: {
    limit: {
      type: Number,
      default: null,
    },
  },
  computed: {
    notificationUnread() {
      return this.$store.getters["userStore/notificationUnread"];
    },
  },
  mounted() {
    this.tabNotification = this.TAB_NOTIFICATION.all.key;
    this.fetchNotifications();
    this.$store.dispatch("userStore/notificationUnread");
  },
  methods: {
    async fetchNotifications(state) {
      let response = await this.$store.dispatch(
        "userStore/fetchNotifications",
        {
          page: this.page,
          "filter[read]":
            this.tabNotification == this.TAB_NOTIFICATION.unread.key
              ? false
              : null,
        }
      );

      if (response?.data?.length) {
        this.page += 1;
        this.notificationList.push(...response.data);
        state?.loaded();
      } else {
        state?.complete();
      }
    },
    async selectNotification(notification_id) {
      this.$router.push({
        name: "notification-details",
        params: { notification_id: notification_id },
      });

      this.notificationList = this.notificationList.map((notification) => {
        if (notification.id == notification_id) {
          notification.read_at = new Date();
        }
        return notification;
      });
    },
    async markAsReadAllNotification() {
      if (this.notificationUnread == 0) return;

      await this.$store.dispatch("userStore/markAsReadAllNotification");
      await this.$store.dispatch("userStore/notificationUnread");

      this.notificationList = this.notificationList.map((notification) => {
        notification.read_at = new Date();
        return notification;
      });
    },
    async notificationChanges(tab) {
      if (this.tabNotification == tab) return;

      this.tabNotification = tab;
      this.notificationList = [];
      this.infiniteId += 1;
      this.page = 1;
    },
  },
};
</script>