<template>
  <div v-if="loading" class="w-full flex justify-center py-8">
    <app-icon-loading iconColor="primary" class=""/>
  </div>
  <div v-else class="grid grid-cols-1 md:grid-cols-2 gap-4">
    <app-form-selection-box
      v-for="paymentGatewayMethod in paymentGatewayMethods"
      :key="paymentGatewayMethod.id"
      :active="paymentGatewayMethod.id == checkedValue?.id"
      @click="selectPaymentMethod(paymentGatewayMethod)"
      :disabled="disabled"
    >
      <app-logo-fpx v-if="paymentGatewayMethod.channel == PAYMENT_CHANNEL.FPX" />
      <app-logo-m1pay class="h-6" v-else-if="paymentGatewayMethod.channel == PAYMENT_CHANNEL.M1PAY" />
      <app-logo-senangpay v-else-if="paymentGatewayMethod.channel == PAYMENT_CHANNEL.SENANG_PAY" />
      <app-logo-gobiz v-else-if="paymentGatewayMethod.channel == PAYMENT_CHANNEL.GO_BIZ || paymentGatewayMethod.channel == PAYMENT_CHANNEL.GO_BIZ_UPP" />
      <app-logo-stripe class="h-10" v-if="paymentGatewayMethod.channel == PAYMENT_CHANNEL.STRIPE" />
      <p class="text-sm antialiased">{{ paymentGatewayMethod.channel ?? "" }}</p>
    </app-form-selection-box>
  </div>
</template>

<script>
import PAYMENT_CHANNEL from '@/utils/const/payment_channel'

export default {
  props: {
    labelText: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    allowFilter: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
    payment_method_id: {
      type: Number,
      default: 0,
    },
  },
   data() {
    return {
      PAYMENT_CHANNEL: PAYMENT_CHANNEL
    }
  },
  watch: {
    payment_method_id: function (newVal, oldValue) {
      this.getPaymentGatewayMethods();
    },
    paymentGatewayMethods(paymentGatewayMethods) {
      if (this.$lodash.isEmpty(paymentGatewayMethods)) {
        this.onValueSelected(null);
      }
    },
  },
  computed: {
    checkedValue: {
      get() {
        if (this.$lodash.isEmpty(this.paymentGatewayMethods)) {
          return {};
        }
        return this.modelValue ?? [];
      },
      set(value) {
        this.onValueSelected(value);
      },
    },

    paymentGatewayMethods() {
      return this.$store.getters["referenceStore/paymentGatewayMethodChannels"];
    },

    loading() {
      return this.$store.getters["referenceStore/loading"];
    },

    serverMessage() {
      return this.$store.getters["referenceStore/message"] ?? "";
    },
  },

  async mounted() {
    this.getPaymentGatewayMethods();
  },

  methods: {
    async getPaymentGatewayMethods() {
      this.$store.commit("referenceStore/resetState");
      this.$store.dispatch(
        "referenceStore/getPaymentGatewayMethodChannels",
        this.payment_method_id
      );
    },

    onValueSelected(value) {
      this.$emit("update:modelValue", value);
    },

    selectPaymentMethod(value) {
      this.checkedValue = value;
    },
  },
};
</script>


