<template>
  <div class="w-full flex flex-row justify-center">
    <div
      class="
        flex flex-row
        justify-self-center
        w-full
        justify-center
        items-center
        text-sm
        text-center
        p-4
        bg-white
      "
      :class="$attrs.class"
    >
      &copy; {{ $t("app.company_name") }}
      <span>&nbsp;.&nbsp;</span>
      <a
        class="text-black"
        href="https://drive.google.com/file/d/1jrOl4mB6Qf3v3jrqBDBVoSaX-7ynUC3R/view?usp=share_link"
        target="_blank"
      >
        {{ $t("general.term_and_conditions") }}
      </a>
      <span>&nbsp;.&nbsp;</span>
      <a class="text-black" :href="contactLink" target="_blank">
        {{ $t("general.contact") }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contactLink:
        "https://tawk.to/chat/" +
        process.env.VUE_APP_TAWKTO_PROPERTY_ID +
        "/" +
        process.env.VUE_APP_TAWKTO_WIDGET_ID,
    }
  }
}
</script>

