<template>
  <div
    class="
      flex flex-col
      items-center
      bg-gray-800
      w-full
      min-h-screen
      h-full
      sm:py-10
      py-6
      md:px-2
    "
  >
    <div class="flex flex-col bg-white rounded-md w-11/12 max-w-full">
      <div class="flex flex-row px-12 pt-8 pb-5">
        <!-- business info -->
        <div class="flex flex-col w-1/2 2xl:w-2/3 space-y-2">
          <app-image
            v-if="getBusinessLogo"
            :src="getBusinessLogo"
            class="flex-none w-24 mb-2 bg-white"
          />
          <app-image
            v-else-if="getBusinessIcon"
            :src="getBusinessIcon"
            class="flex-none w-24 mb-2 bg-white"
          />
          <p class="text-gray-900 text-slightly-xs uppercase font-semibold">
            {{ business?.name }}
          </p>
          <p class="text-gray-400 text-xs uppercase">
            {{ business?.detail?.company_reg_no }}
          </p>
        </div>
        <!-- end business info -->
        <!-- invoice summary -->
        <div class="w-1/2 2xl:w-1/3 space-y-2">
          <p class="font-bold text-lg text-center md:text-right uppercase">
            {{ $t("layout.header.invoice") }}
          </p>
          <div
            class="
              flex flex-col
              space-y-1
              text-slightly-xs text-right text-gray-600
            "
          >
            <div class="flex flex-row space-x-2 justify-end">
              <p>{{ $t("invoice.invoice_pdf.invoice_no") }}:</p>
              <p class="w-32">
                {{ invoice.invoice_no }}
              </p>
            </div>
            <div class="flex flex-row space-x-2 justify-end">
              <p>{{ $t("invoice.invoice_pdf.date") }}:</p>
              <p class="w-32">
                {{
                  invoice.created_at
                    ? $moment(invoice.created_at).format("DD MMM YYYY")
                    : "-"
                }}
              </p>
            </div>
            <div
              v-for="att in nonEmptyCustomAttributes"
              :key="att.key"
              class="flex flex-row space-x-2 justify-end break-words"
            >
              <p class="capitalize">{{ att.key }}:</p>
              <p class="w-32 capitalize">{{ att.value }}</p>
            </div>
          </div>
          <div class="bg-gray-100 text-right text-gray-700 p-2.5 rounded-md">
            <p class="text-base font-semibold">
              {{ $t("invoice.invoice_pdf.total_due") }}:
              {{ $formats.currency(invoice.currency, invoice.total_amount) }}
            </p>
            <p class="text-slightly-sm font-semibold">
              {{ $t("invoice.invoice_pdf.due_on") }}:
              {{
                invoice.payment_term_date
                  ? $moment(invoice.payment_term_date).format("DD MMM YYYY")
                  : "-"
              }}
            </p>
          </div>
        </div>
        <!-- end invoice summary -->
      </div>

      <!-- invoice addresses -->
      <div class="flex flex-row bg-gray-50 px-12 py-4 border">
        <div class="w-1/4 space-y-2">
          <p class="text-sm text-gray-600 font-semibold uppercase">
            {{ $t("invoice.invoice_pdf.to") }}
          </p>
          <p class="text-sm text-gray-900 font-semibold">{{ customer.name }}</p>
          <p class="text-slightly-xs text-gray-400">
            {{ customer.email }}
          </p>
          <p class="text-slightly-xs text-gray-400">
            {{ customer.phone_no }}
          </p>
        </div>
        <div class="w-1/4">
          <div v-if="customerBillingAddress" class="space-y-2">
            <p class="text-sm font-semibold uppercase">
              {{ $t("invoice.invoice_pdf.billing_address") }}
            </p>
            <p class="text-sm text-gray-900 font-semibold">
              {{ customer.name }}
            </p>
            <p class="text-slightly-xs whitespace-pre-line text-gray-400">
              {{ customerBillingAddress }}
            </p>
          </div>
        </div>
        <div class="w-1/4">
          <div v-if="customerShippingAddress" class="space-y-2">
            <p class="text-sm font-semibold uppercase">
              {{ $t("invoice.invoice_pdf.shipping_address") }}
            </p>
            <p class="text-sm text-gray-900 font-semibold">
              {{ customer.name }}
            </p>
            <p class="text-slightly-xs whitespace-pre-line text-gray-400">
              {{ customerShippingAddress }}
            </p>
          </div>
        </div>
        <div class="w-1/4">
          <div v-if="invoice.memo" class="space-y-2 h-full border-l text-right">
            <p class="text-sm font-semibold uppercase">
              {{ $t("invoice.invoice_pdf.memo") }}
            </p>
            <p
              class="
                text-slightly-xs
                whitespace-pre-line
                text-gray-400
                break-words
              "
            >
              {{ invoice.memo }}
            </p>
          </div>
        </div>
      </div>

      <!-- invoice items -->
      <div class="overflow-x-auto">
        <table
          class="
            min-w-full
            divide-y divide-divider-gray
            border-b border-divider-gray
          "
        >
          <thead class="text-gray-400">
            <tr>
              <td class="pl-12">
                {{ $t("invoice.create.product_description") }}
              </td>
              <td>{{ $t("invoice.create.product_qty") }}</td>
              <td>{{ $t("invoice.create.product_price") }}</td>
              <td>{{ $t("invoice.create.product_tax") }}</td>
              <td class="pr-12">{{ $t("invoice.create.product_amount") }}</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in invoice.items" :key="item._id">
              <td class="pl-12">{{ item.name }}</td>
              <td>{{ item.quantity }}</td>
              <td>
                {{
                  `${$formats.currency(item.currency, item.price)}/${
                    item.unit_label
                  }`
                }}
              </td>
              <td>
                <span v-if="item.tax_rate"
                  >{{ $t("invoice.create.tax") }} {{ `${item.tax_rate}%` }}
                  {{
                    item.tax_type === "inclusive"
                      ? `${$t(`tax.types.${item.tax_type}_short`)}`
                      : ""
                  }}</span
                >
                <span v-else>-</span>
              </td>
              <td>{{ $formats.currency(item.currency, item.price) }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- invoice items summary -->
      <div
        class="
          bg-white
          py-4
          inline-block
          min-w-full
          sm:px-12
          lg:px-12
          justify-center
        "
      >
        <div class="flex flex-col md:flex-row gap-4">
          <div class="w-1/4"></div>
          <div class="w-1/5"></div>
          <div class="w-1/2 sm:items-end text-right">
            <div class="grid grid-cols-2 sm:text-right">
              <div class="text-sm">
                <p class="text-sm font-bold pb-2">
                  {{ $t("invoice.invoice_pdf.subtotal") }}:
                </p>
                <p class="text-sm font-bold pb-2">
                  {{ $t("invoice.invoice_pdf.total_excluding_tax") }}:
                </p>
                <template
                  v-for="invoiceItem in invoice.items"
                  :key="invoiceItem.key"
                >
                  <p
                    v-if="invoiceItem.tax_id"
                    class="text-sm pb-1 text-gray-600"
                  >
                    {{
                      getUsedTaxName(
                        invoiceItem.tax_name,
                        invoiceItem.tax_rate,
                        invoiceItem.tax_type
                      )
                    }}
                  </p>
                </template>

                <div v-for="att in nonEmptyCustomAttributes" :key="att.key">
                  <p class="text-sm pb-1 text-gray-600 break-words">
                    {{ att.key }}:
                  </p>
                </div>

                <p class="text-lg py-2 font-semibold text-primary">
                  {{ $t("invoice.invoice_pdf.total_due") }}:
                </p>
              </div>
              <div class="text-sm items-end sm:text-right">
                <p class="text-sm font-bold pb-2">
                  {{
                    $formats.currency(invoice.currency, getSummarySubtotal())
                  }}
                </p>
                <p class="text-sm font-bold pb-2 md:pt-5 lg:pt-5 xl:pt-0">
                  {{
                    $formats.currency(
                      invoice.currency,
                      getSummaryAmountExcludingTax()
                    )
                  }}
                </p>
                <template
                  v-for="invoiceItem in invoice.items"
                  :key="invoiceItem.key"
                >
                  <p
                    v-if="invoiceItem.tax_id"
                    class="text-sm pb-4 md:py-5 lg:pb-1 lg:py-2 xl:py-0  text-gray-600"
                  >
                    {{
                      $formats.currency(
                        invoiceItem.currency,
                        getUsedAmountWithTax(
                          getUsedAmount(
                            invoiceItem.price,
                            invoiceItem.quantity
                          ),
                          invoiceItem.tax_rate,
                          invoiceItem.tax_type
                        ).toString()
                      )
                    }}
                  </p>
                </template>
                <p
                  class="text-sm pb-1 text-gray-600 break-words"
                  v-for="att in nonEmptyCustomAttributes"
                  :key="att.key"
                >
                  {{ att.key }}:
                </p>
                <p
                  class="
                    text-lg
                    py-2
                    font-semibold
                    text-primary
                    md:pt-5
                    lg:pt-2
                  "
                >
                  {{
                    $formats.currency(invoice.currency, invoice.total_amount)
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- footer -->
      <div
        class="
          border-t border-b border-gray-200
          py-5
          px-12
          mt-20
          inline-block
          min-w-full
          justify-center
          bg-gray-50
        "
      >
        <p class="text-sm text-gray-400">{{ invoice.footer }}</p>
      </div>
      <div class="justify-center py-4 bg-gray-50">
        <app-logo-powered-by-swipe-dark />
      </div>
    </div>
  </div>
</template>
<style scoped>
table thead tr td {
  padding-top: 10px;
  padding-bottom: 10px;
}
table tbody tr td {
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>
<script>
import TAX_TYPE from "@/utils/const/tax_type";

export default {
  props: {
    previewMode: {
      type: Boolean,
      default: false,
    },
    masterBusinessMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showDetails: false,
      testMode: false,
      TAX_TYPE: TAX_TYPE,
    };
  },

  async mounted() {
    if (this.$route.query.id == null) {
      this.testMode = this.$store.getters["businessStore/test_mode"] ?? false;
      await this.getDataForPreview();
      return;
    }

    if (this.$route.query.test != null && this.$route.query.test == "true") {
      this.testMode = true;
    }

    this.$store.commit("businessStore/setTestModePublicPage", this.testMode);

    await this.getDataForPayment(this.$route.query.id);
  },

  computed: {
    loading() {
      return this.$store.getters["invoiceStore/loading"];
    },
    invoice() {
      return this.$store.getters["invoiceStore/invoice"];
    },
    customer() {
      return this.$store.getters["customerStore/customer"];
    },
    customerBillingAddress() {
      return this.$store.getters["customerStore/customerAddress"]("billing");
    },
    customerShippingAddress() {
      return this.$store.getters["customerStore/customerAddress"]("shipping");
    },
    errors() {
      return this.$store.getters["invoiceStore/errors"] ?? this.emptyErrors;
    },
    business() {
      return this.masterBusinessMode
        ? this.payment_link.business
        : this.$store.getters["businessStore/business"];
    },
    getBusinessLogo() {
      return this.business?.media?.find(
        (media) => media.collection_name == "logo"
      )?.original_url;
    },
    getBusinessIcon() {
      return this.business.media?.find(
        (media) => media.collection_name == "icon"
      )?.original_url;
    },
    nonEmptyCustomAttributes() {
      return this.invoice.custom_attributes.filter(
        (item) => item.key && item.value
      );
    },
  },

  methods: {
    async getDataForPreview() {
      await this.$store.dispatch(
        "businessStore/getBusinessFromSelectedRoleBusinessId"
      );
    },
    toggleDetails() {
      this.showDetails = !this.showDetails;
    },
    getUsedTaxName(name, rate, type) {
      let taxUsedTypePostfix = type == TAX_TYPE.INCLUSIVE ? "incl." : "excl.";
      return `Tax ${name} (${rate}% ${taxUsedTypePostfix})`;
    },
    getUsedAmount(price, quantity) {
      return price * quantity;
    },
    getUsedAmountWithTax(amount, rate, type) {
      if (type == TAX_TYPE.INCLUSIVE || type == TAX_TYPE.EXCLUSIVE) {
        return (amount * rate) / 100;
      } else {
        return amount;
      }
    },
    getSummaryAmountExcludingTax() {
      let newAmount = 0;

      this.invoice.items.forEach((item) => {
        if (item.tax_type == TAX_TYPE.INCLUSIVE) {
          newAmount +=
            this.getUsedAmount(item.price, item.quantity) -
            this.getUsedAmountWithTax(
              this.getUsedAmount(item.price, item.quantity),
              item.tax_rate,
              item.tax_type
            );
        } else {
          newAmount = newAmount + this.getUsedAmount(item.price, item.quantity);
        }
      });

      return newAmount;
    },
    getSummarySubtotal() {
      let newAmount = 0;

      this.invoice.items.forEach((item) => {
        newAmount += this.getUsedAmount(item.price, item.quantity);
      });

      return newAmount;
    },
  },
};
</script>
