import baseStore from "../base-store";
import AuthRepository from "../../data/repository/auth-repository";
import { logEvent } from "firebase/analytics";
import Role from "@/utils/const/role";

const initialState = {
  email: "",
  name: "",
  phone_no: "",
  password: "",
  confirm_password: "",
  remember_me: false,
  country: {
    id: 160,
    name: "Malaysia",
    icon: process.env.VUE_APP_BACKEND_URL + "/image/flag/mys.svg",
  },
  user_agreement: false,
  max_business: 5,
};

const RoleMasterBusiness = [
  Role.MASTER_BUSINESS_OWNER,
  Role.MASTER_BUSINESS_ADMIN,
  Role.MASTER_BUSINESS_MANAGER,
  Role.MASTER_BUSINESS_DEVELOPER,
  Role.MASTER_BUSINESS_SUPPORT,
]

export default {
  namespaced: true,

  state: {
    ...baseStore.state,
    user: initialState,
    token: null,
    passwordValidated: true,
    selectedRole: {},
    isMasterBusinessRole: false
  },

  getters: {
    ...baseStore.getters,
    user(state) {
      return state.user;
    },

    authenticated(state) {
      return state.token != null && state.user != null;
    },

    hasVerifiedEmail(state) {
      return state.user.email_verified_at != null;
    },

    passwordValidated(state) {
      return state.passwordValidated;
    },

    selectedRole(state) {
      return state.selectedRole;
    },

    selectionRoles(state) {
      return state.user.model_has_roles?.filter(
        (modelHasRole) => modelHasRole.role_id != Role.USER
      );
    },

    adminToken(state) {
      const adminRole = state.user.model_has_roles?.filter(
        (modelHasRole) => modelHasRole.role_id != Role.ADMIN
      );

      return (adminRole != null) ? state.token : null;
    },

    hasRole: (state) => (role) => {
      return Array.isArray(role)
        ? role.some((role_id) =>
          state.user.model_has_roles
            .map((modelHasRole) => modelHasRole.role_id)
            .includes(role_id)
        )
        : state.user.model_has_roles
          .map((modelHasRole) => modelHasRole.role_id)
          .includes(role);
    },

    hasRoleMasterBusiness: (state) => (roleId) => {
      roleId = roleId ?? state.selectedRole.role_id;
      return Array.isArray(roleId)
        ? roleId.some((role_id) => RoleMasterBusiness.includes(role_id))
        : RoleMasterBusiness.includes(roleId);
    },

    roles: (state) => {
      return state.user.model_has_roles.map(
        (modelHasRole) => modelHasRole.role_id
      );
    },

    isMasterBusinessRole(state) {
      return state.isMasterBusinessRole;
    }
  },

  mutations: {
    ...baseStore.mutations,

    setUser(state, user) {
      state.user = user;
    },

    setToken(state, token) {
      state.token = token;
    },

    setAuth(state, auth) {
      state.user = auth.user ?? initialState;
      state.token = auth.token;

      if (!state.selectedRole && state.user.model_has_roles?.length > 0) {
        const authRepository = new AuthRepository();

        var selectedRole = state.user.model_has_roles
          .filter((modelHasRole) => modelHasRole.role_id != Role.USER)
          .filter((modelHasRole) => modelHasRole.default == true)[0];

        if (selectedRole == null) {
          selectedRole = state.user.model_has_roles.filter(
            (modelHasRole) => modelHasRole.role_id != Role.USER
          )[0];
        }

        authRepository.setSelectedRole(selectedRole);
        state.selectedRole = selectedRole;
      } else if (state.selectedRole && state.user.model_has_roles?.length > 0) {
        const authRepository = new AuthRepository();

        let selectedRole = state.user.model_has_roles.filter(
          (modelHasRole) => modelHasRole.id == state.selectedRole.id
        )[0];

        if (selectedRole != null) {
          authRepository.setSelectedRole(selectedRole);
          state.selectedRole = selectedRole;
        }
      }
    },

    setPasswordValidated(state, passwordValidated) {
      state.passwordValidated = passwordValidated;
    },

    setSelectedRole(state, selectedRole) {
      state.selectedRole = selectedRole;
    },

    setIsMasterBusinessRole(state, selectedRole) {
      if (selectedRole == null) {
        state.isMasterBusinessRole = false;
        return;
      }

      state.isMasterBusinessRole = RoleMasterBusiness.includes(selectedRole?.role_id);
    }
  },

  actions: {
    checkAuth(state, init = true) {
      const loggedAuth = new AuthRepository().getLocalAuth();
      const selectedRole = new AuthRepository().getSelectedRole();

      if (loggedAuth == null) return;

      if (init) {
        state.commit("setAuth", loggedAuth);
        state.commit("setSelectedRole", selectedRole);
        state.commit("setIsMasterBusinessRole", selectedRole);
        state.commit("setLoading", false);
      }

      return loggedAuth;
    },

    async login(state, user) {
      const authRepository = new AuthRepository();

      if (!user.email) {
        state.commit("setError", {
          email: [
            this.$t("general.field_required", {
              name: this.$t("general.email"),
            }),
          ],
        });
        return null;
      }

      if (!user.password) {
        state.commit("setError", {
          password: [
            this.$t("general.field_required", {
              name: this.$t("general.password"),
            }),
          ],
        });
        return null;
      }

      const loginResult = await authRepository.login(
        user.email,
        user.password,
        user.remember_me
      );

      if (loginResult.message != null) {
        logEvent(this.$analytics, "login", { result: "failed" });
        state.commit("setMessage", loginResult.message);
        return null;
      }

      state.commit("setLoading", false);
      state.commit("setAuth", loginResult.data);
      logEvent(this.$analytics, "login", { result: "success" });
      return loginResult.data;
    },

    async resendVerificationEmail(state) {
      const resendResult = await new AuthRepository().resendVerificationEmail();

      if (resendResult.message != null) {
        logEvent(this.$analytics, "resent-verification-email", {
          result: "failed",
        });
        state.commit("setErrorMessage", resendResult);
        return null;
      }

      logEvent(this.$analytics, "resent-verification-email", {
        result: "success",
      });
      state.commit("setLoading", false);
      return resendResult.data;
    },

    async  verifyEmailAdmin(state, user) {
    const authRepository = new AuthRepository();
    const email = authRepository.verifyEmailAdmin(
     user.email
    );

    if (email.message != null) {
      logEvent(this.$analytics, "verify-email-admin", { result: "failed" });
      state.commit("setErrorMessage",email);
      return null;
    }

    logEvent(this.$analytics, "verify-email-admin", { result: "success" });
    state.commit("setLoading", false);
    return email.data;
    },

    async register(state, user) {

      let validateErrorForm = {};

      if (user.email.trim().length == 0) {
        validateErrorForm.email = [
          this.$t("general.field_required", {
            name: this.$t("general.email"),
          }),
        ];
      }

      if (user.name.trim().length == 0) {
        validateErrorForm.name = [
          this.$t("general.field_required", {
            name: this.$t("general.name"),
          }),
        ];
      }

      if (user.phone_no.trim().length == 0) {
        validateErrorForm.phone_no = [
          this.$t("general.field_required", {
            name: this.$t("general.phone_no"),
          }),
        ];
      }

      if (user.country == {}) {
        validateErrorForm.country = [
          this.$t("general.field_required", {
            name: this.$t("general.country"),
          }),
        ];
      }

      if (user.password.trim().length == 0) {
        validateErrorForm.password = [
          this.$t("general.field_required", {
            name: this.$t("general.password"),
          }),
        ];
      }

      if (user.user_agreement == false) {
        validateErrorForm.user_agreement = [
          this.$t("general.field_required", {
            name: this.$t("auth.register.user_agreement"),
          }),
        ];
      }

      if (!this.$lodash.isEmpty(validateErrorForm)) {
        state.commit("setError", validateErrorForm);
        return null
      }

      if (!state.getters.passwordValidated) {
        state.commit("setLoading", false);
        return null;
      }

      const registerResult = await new AuthRepository().register(user);

      if (registerResult.message != null) {
        logEvent(this.$analytics, "register", { result: "failed" });
        state.commit("setErrorMessage", registerResult);
        return null;
      }

      state.commit("setLoading", false);
      state.commit("setAuth", registerResult.data);
      logEvent(this.$analytics, "register", { result: "success" });
      return registerResult.data;
    },

    async forgotPassword(state, user) {
      if (user.email.trim().length == 0) {
        state.commit("setError", {
          email: [
            this.$t("general.field_required", {
              name: this.$t("general.email"),
            }),
          ],
        });
        return null;
      }

      state.commit("setLoading", true);

      const forgotPasswordResult = await new AuthRepository().forgotPassword(
        user.email
      );

      if (forgotPasswordResult.message != null) {
        logEvent(this.$analytics, "forgot-password", { result: "failed" });
        state.commit("setErrorMessage", forgotPasswordResult);
        return null;
      }

      logEvent(this.$analytics, "forgot-password", { result: "success" });
      state.commit("setLoading", false);
      return forgotPasswordResult.data;
    },

    async resetPassword(state, data) {
      const user = data.user;

      if (!user.password) {
        state.commit("setError", {
          password: [
            this.$t("general.field_required", {
              name: this.$t("general.password"),
            }),
          ],
        });
        return null;
      }

      if (!user.confirm_password) {
        state.commit("setError", {
          confirm_password: [
            this.$t("general.field_required", {
              name: this.$t("general.confirm_password"),
            }),
          ],
        });
        return null;
      }

      if (user.password != user.confirm_password) {
        state.commit("setError", {
          confirm_password: [this.$t("auth.login.reset_password_error")],
        });
        return null;
      }

      const resetPasswordResult = await new AuthRepository().resetPassword(
        data.token,
        user.password
      );

      if (resetPasswordResult.message != null) {
        logEvent(this.$analytics, "reset-password", { result: "failed" });
        state.commit("setErrorMessage", resetPasswordResult);
        return null;
      }

      logEvent(this.$analytics, "reset-password", { result: "success" });
      state.commit("setLoading", false);
      return resetPasswordResult.data;
    },

    async refreshUser(state) {
      const refreshUserResult = await new AuthRepository().getUserFromToken();

      if (refreshUserResult.message != null) {
        logEvent(this.$analytics, "get-user-from-token", { result: "failed" });
        state.commit("setErrorMessage", refreshUserResult);
        return null;
      }

      logEvent(this.$analytics, "get-user-from-token", { result: "success" });
      state.commit("setLoading", false);
      state.commit("setAuth", refreshUserResult.data);
      return refreshUserResult.data;
    },

    async getAuditTrails(state, queryParams = "") {
      state.commit("setApiPaginationLoading", true);
      const authRepository = new AuthRepository();

      const auditTrailResult = await authRepository.getAuditTrails(queryParams);

      if (auditTrailResult.message != null) {
        logEvent(this.$analytics, "get-audit-trails", { result: "failed" });
        state.commit("setErrorMessage", auditTrailResult);
      } else {
        logEvent(this.$analytics, "get-audit-trails", { result: "success" });
        state.commit("setApiPaginationResponse", auditTrailResult);
      }

      state.commit("setApiPaginationLoading", false);

      return auditTrailResult;
    },

    setSelectedRole(state, roleId) {
      const authRepository = new AuthRepository();
      const user = authRepository.getLocalUser();

      var selectedRole = null;
      user.model_has_roles.forEach((role) => {
        if (role.id == roleId) {
          selectedRole = role;
          authRepository.setSelectedRole(role);
        }
      });

      if (selectedRole == null) {
        state.commit("setMessage", this.$t("auth.role.not_found"));
      }

      state.commit("setSelectedRole", selectedRole);
      state.commit("setIsMasterBusinessRole", selectedRole);
      return selectedRole;
    },

    async setSelectedDefaultRole(state, data) {
      const authRepository = new AuthRepository();

      let result = await authRepository.assignDefaultRole(
        data.modelHasRole.id,
        data.default
      );

      if (result.message != null) {
        logEvent(this.$analytics, "assign-default-role", { result: "failed" });
        state.commit("setErrorMessage", result);
      } else {
        logEvent(this.$analytics, "assign-default-role", { result: "success" });
        authRepository.setSelectedRole(data.modelHasRole);
      }

      return result;
    },

    setSelectedRoleByBusinessId(state, businessId) {
      const authRepository = new AuthRepository();
      const user = authRepository.getLocalUser();

      var selectedRole = null;
      user.model_has_roles.forEach((role) => {
        if (role.business_id != null && role.business_id == businessId) {
          selectedRole = role;
          authRepository.setSelectedRole(role);
        }
      });

      if (selectedRole == null) {
        state.commit("setMessage", this.$t("auth.role.not_found"));
        return;
      }

      state.commit("setSelectedRole", selectedRole);
      state.commit("setIsMasterBusinessRole", selectedRole);
      return selectedRole;
    },

    clearSelectedRole(state) {
      const authRepository = new AuthRepository();
      authRepository.setSelectedRole(null);
      state.commit("setSelectedRole", {});
      state.commit("setIsMasterBusinessRole", null);

      return null;
    },

    logoutUser(state) {
      new AuthRepository().logout();
      logEvent(this.$analytics, "logout", { result: "success" });
      state.commit("setAuth", {
        user: {
          email: "",
          name: "",
          password: "",
          confirm_password: "",
          remember_me: false,
          country: {
            id: 160,
            name: "Malaysia",
            icon: process.env.VUE_APP_BACKEND_URL + "/image/flag/mys.svg",
          },
          accept_marketing_email: false,
        },
        token: null,
      });
    },
  },
};
