<template>
  <app-modal class="rounded-md" title="Withdraw" :showCancelButton="false">
    <template v-slot:title>
      <p>{{ $t("wallet.payout.withdraw_amount") }}</p>
    </template>

    <template v-slot:body>
      <app-form-select-master-business-list-business
        class="w-full"
        v-model="selectedBusiness"
        :loading="loading"
        :errorMessage="errors.business_id"
      />

      <div class="text-sm space-x-1 flex flex-row border-b py-4">
        <p class="text-gray-400">{{ $t("wallet.payout.bank_acc") }} :</p>
        <p class="text-gray-400">
          {{
            selectedBusiness?.bank.bank.display_name ??
            business.bank.bank.display_name
          }}
        </p>
        <p class="text-gray-400">
          {{ selectedBusiness?.bank.account_no ?? business.bank.account_no }}
        </p>
      </div>

      <div
        class="
          text-sm
          space-x-1
          flex flex-row
          items-center
          py-4
          justify-between
        "
      >
        <div class="w-full flex space-x-1 place-items-center">
          <p class="flex-flex-row">
            {{ $t("wallet.payout.withdraw_menu") }}
          </p>
          <p class="font-bold">
            {{
              $formats.currency(
                walletBalance.currency,
                walletBalance.balance ?? 0.0
              )
            }}
          </p>
        </div>
        <app-button-outline
          class="flex-none items-center"
          @click="onClickWithdrawAll"
        >
          {{ $t("wallet.payout.withdraw_all") }}
        </app-button-outline>
      </div>
      <div class="">
        <app-form-input
          type="number"
          :placeholder="$t('wallet.payout.withdraw_amount_placeholder')"
          v-model="amount"
          :errorMessage="errors.amount"
        />
      </div>
    </template>

    <template v-slot:action>
      <app-button
        class="w-full sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        @click="onClickRequestWithdraw"
        :showf70Icon="false"
      >
        {{ $t("wallet.payout.request_withdraw") }}
      </app-button>
    </template>
  </app-modal>
</template>

<script>
export default {
  data() {
    return {
      amount: null,
      selectedBusiness: null,
    };
  },

  props: {
    withdraw: {
      type: Object,
      default: null,
    },
  },
  computed: {
    loading() {
      return this.$store.getters["walletStore/loading"];
    },
    errors() {
      return this.$store.getters["walletStore/errors"];
    },
    business() {
      return this.$store.getters["businessStore/business"];
    },
    walletBalance() {
      return this.$store.getters["walletStore/walletBalance"];
    },
  },
  watch: {
    selectedBusiness(value) {
      this.$store.dispatch("walletStore/getWalletBalance", value.id);
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },

    refresh() {
      this.$emit("refresh", true);
    },

    onClickWithdrawAll() {
      this.amount = this.walletBalance.balance ?? 0.0;
    },

    async onClickRequestWithdraw() {
      const result = await this.$store.dispatch(
        "walletStore/createPayoutRequest",
        {
          business_id: this.selectedBusiness?.id,
          amount: this.amount,
          currency: "MYR",
        }
      );

      if (result != null) {
        this.amount = null;
        this.close();
        this.refresh();
        this.$notify(
          {
            group: "success",
            title: "Saved!",
          },
          3000
        );
      }
    },
  },
  mounted() {
    this.$store.dispatch("walletStore/getWalletBalance");
  },
};
</script>
