<template>
  <div>
    <app-loading-page :loading="select_loading" />
    <div class="relative bg-gray-100 lg:py-2 border-b z-30">
      <div class="lg:container mx-auto lg:px-5">
        <!-- start mobile view -->
        <div class="flex lg:hidden">
          <!-- active account mobile -->
          <app-button-business-status
            :route_name="business_route"
            :full_width="true"
          />
        </div>
        <!-- end mobile view -->

        <div
          class="
            px-5
            py-2
            flex
            m-auto
            lg:px-0
            container
            items-center
            justify-between
          "
        >
          <!-- left menu -->
          <div class="lg:flex flex-1 hidden">
            <!-- active account -->
            <app-button-business-status
              v-if="!select_loading"
              :route_name="business_route"
              :full_width="false"
            />
          </div>

          <!-- center menu -->
          <!-- <div class="lg:flex flex-0 hidden">
            <app-logo-swipe class="h-8 px-5" />
          </div>
          -->

          <!-- right menu -->
          <div
            class="
              flex flex-wrap flex-auto
              sm:flex-row
              lg:justify-end
              md:justify-between
              justify-end
              items-center
              sm:space-x-6
              space-x-2
              sm:space-y-0
              space-y-3
            "
          >
            <div
              class="
                flex flex-col
                sm:flex-row
                place-items-center
                sm:space-x-3
                space-x-0
                sm:space-y-0
                space-y-3
              "
            >
              <!-- select role/business -->
              <app-button-dropdown
                :showArrow="false"
                position="left"
                width="w-56"
              >
                <template v-slot="{ is_open }">
                  <app-button
                    :showf70Icon="false"
                    class="flex justify-between space-x-2"
                    width="sm:w-56 w-48"
                  >
                    <span class="flex-none">
                      <app-icon-loading
                        v-if="select_loading"
                        customClass="w-5 h-5"
                      />
                      <div v-else-if="selectedRole?.business">
                        <app-image
                          v-if="getBusinessIcon(selectedRole.business)"
                          :src="getBusinessIcon(selectedRole.business)"
                          class="rounded-full flex-none w-5 h-5 bg-white"
                        />
                        <div
                          v-else-if="getBusinessLogo(selectedRole.business)"
                          class="
                            rounded-full
                            relative
                            overflow-hidden
                            w-5
                            h-5
                            bg-white
                          "
                        >
                          <img
                            :src="getBusinessLogo(selectedRole.business)"
                            class="w-auto h-full"
                          />
                        </div>
                        <app-icon
                          v-else
                          name="BriefcaseIcon"
                          class="h-5 w-5 flex-none"
                        />
                      </div>
                      <app-icon v-else name="UserIcon" class="h-5 w-5" />
                    </span>
                    <div class="flex truncate h-full items-center space-x-1">
                      <p class="truncate flex-auto">
                        {{
                          getDisplayRoleName(selectedRole)
                            .replace("_", " ")
                            .replace("_", " ")
                        }}
                      </p>
                      <sup
                        class="
                          p-1
                          w-auto
                          rounded-md
                          text-center text-xxs
                          bg-success-badge
                          text-green-900
                          border border-green-100
                        "
                        v-if="checkIfMasterBusiness(selectedRole)"
                      >
                        {{ $t("user.detail.master_business") }}
                      </sup>
                    </div>
                    <span class="flex-none">
                      <app-icon
                        :name="is_open ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                        class="w-5 h-5 pointer-events-none"
                      />
                    </span>
                  </app-button>
                </template>

                <template #menu-items>
                  <!-- merchant -->
                  <app-button-dropdown-item
                    v-for="(modelHasRole, index) in selectionRoles.filter(
                      function (merchant) {
                        return (
                          merchant.business != null &&
                          merchant.role_id != Role.MASTER_BUSINESS_OWNER
                        );
                      }
                    )"
                    :key="modelHasRole.id"
                    :class="{ 'rounded-t-md': index == 0 }"
                    :active="isSelectedRole(modelHasRole)"
                    :disabled="isSelectedRole(modelHasRole)"
                    :fullWidth="false"
                    @onMenuClick="selectBusinessRole(modelHasRole)"
                    class="flex flex-col font-medium"
                  >
                    <div class="flex justify-between h-10 w-full px-3">
                      <span class="flex-none w-8 h-full flex items-center">
                        <div v-if="modelHasRole.business">
                          <app-image
                            v-if="getBusinessIcon(modelHasRole.business)"
                            :src="getBusinessIcon(modelHasRole.business)"
                            class="rounded-full w-5 h-5 flex-none"
                            spinnerSize="3px"
                          />

                          <div
                            v-else-if="getBusinessLogo(modelHasRole.business)"
                            class="
                              rounded-full
                              relative
                              overflow-hidden
                              w-5
                              h-5
                              bg-white
                            "
                          >
                            <img
                              :src="getBusinessLogo(modelHasRole.business)"
                              class="w-auto h-full"
                            />
                          </div>
                          <app-icon
                            v-else
                            name="BriefcaseIcon"
                            class="h-5 w-5 flex-none"
                          />
                        </div>
                        <app-icon v-else name="UserIcon" class="h-5 w-5" />
                      </span>
                      <div
                        class="
                          flex-grow
                          h-full
                          flex
                          items-center
                          space-x-1
                          truncate
                        "
                      >
                        <p class="truncate ...">
                          {{ getDisplayRoleName(modelHasRole) }}
                        </p>
                        <sup
                          class="
                            p-1
                            w-auto
                            rounded-md
                            text-center text-xxs
                            bg-success-badge
                            text-green-900
                            border border-green-100
                          "
                          v-if="checkIfMasterBusiness(modelHasRole)"
                        >
                          {{ $t("user.detail.master_business") }}
                        </sup>
                      </div>
                      <div
                        class="
                          flex-none flex flex-inline
                          h-full
                          items-center
                          w-10
                        "
                      >
                        <app-icon-loading
                          v-if="
                            select_default_loading &&
                            select_default_role.id == modelHasRole.id
                          "
                          customClass="w-5 h-5 text-primary"
                        />
                        <app-icon
                          v-else-if="this.selectionRoles?.length > 1"
                          @click.stop.prevent="assignDefaultRole(modelHasRole)"
                          name="StarIcon"
                          class="
                            h-5
                            w-5
                            text-gray-300
                            hover:text-gray-500
                            cursor-pointer
                            flex-none
                          "
                          :class="{
                            'text-primary hover:text-primary':
                              modelHasRole.default,
                          }"
                        />
                        <app-icon
                          v-if="modelHasRole.business != null"
                          @click.stop.prevent="
                            selectBusinessRole(modelHasRole, settings_route)
                          "
                          name="CogIcon"
                          class="
                            h-5
                            w-5
                            hover:text-gray-500
                            cursor-pointer
                            flex-none
                            text-gray-600
                          "
                        />
                      </div>
                    </div>
                    <hr class="w-full border-gray-200" />
                  </app-button-dropdown-item>

                  <!-- master merchant -->
                  <template v-if="hasGroupMasterMerchant()">
                    <p
                      class="
                        bg-primary
                        text-white text-sm
                        font-normal
                        px-4
                        py-2
                      "
                    >
                      {{ $t("layout.header.master_merchant") }}
                    </p>
                    <app-button-dropdown-item
                      v-for="(modelHasRole, index) in selectionRoles.filter(
                        (merchant) => merchant.group_id == Group.MASTER_MERCHANT
                      )"
                      :key="modelHasRole.id"
                      :class="{ 'rounded-t-md': index == 0 }"
                      :active="isSelectedRole(modelHasRole)"
                      :disabled="isSelectedRole(modelHasRole)"
                      :fullWidth="false"
                      @onMenuClick="selectBusinessRole(modelHasRole)"
                      class="flex flex-col font-medium"
                    >
                      <div class="flex justify-between h-10 w-full px-3">
                        <span class="flex-none w-8 h-full flex items-center">
                          <div v-if="modelHasRole.business">
                            <app-image
                              v-if="getBusinessIcon(modelHasRole.business)"
                              :src="getBusinessIcon(modelHasRole.business)"
                              class="rounded-full w-5 h-5 flex-none"
                              spinnerSize="3px"
                            />

                            <div
                              v-else-if="getBusinessLogo(modelHasRole.business)"
                              class="
                                rounded-full
                                relative
                                overflow-hidden
                                w-5
                                h-5
                                bg-white
                              "
                            >
                              <img
                                :src="getBusinessLogo(modelHasRole.business)"
                                class="w-auto h-full"
                              />
                            </div>
                            <app-icon
                              v-else
                              name="BriefcaseIcon"
                              class="h-5 w-5 flex-none"
                            />
                          </div>
                          <app-icon v-else name="UserIcon" class="h-5 w-5" />
                        </span>
                        <div
                          class="
                            flex-grow
                            h-full
                            flex
                            items-center
                            space-x-1
                            truncate
                          "
                        >
                          <p class="truncate ...">
                            {{ getDisplayRoleName(modelHasRole) }}
                          </p>
                          <sup
                            class="
                              p-1
                              w-auto
                              rounded-md
                              text-center text-xxs
                              bg-success-badge
                              text-green-900
                              border border-green-100
                            "
                            v-if="checkIfMasterBusiness(modelHasRole)"
                          >
                            {{ $t("user.detail.master_business") }}
                          </sup>
                        </div>
                        <div
                          class="
                            flex-none flex flex-inline
                            h-full
                            items-center
                            w-10
                          "
                        >
                          <app-icon-loading
                            v-if="
                              select_default_loading &&
                              select_default_role.id == modelHasRole.id
                            "
                            customClass="w-5 h-5 text-primary"
                          />
                          <app-icon
                            v-else-if="this.selectionRoles?.length > 1"
                            @click.stop.prevent="
                              assignDefaultRole(modelHasRole)
                            "
                            name="StarIcon"
                            class="
                              h-5
                              w-5
                              text-gray-300
                              hover:text-gray-500
                              cursor-pointer
                              flex-none
                            "
                            :class="{
                              'text-primary hover:text-primary':
                                modelHasRole.default,
                            }"
                          />
                          <app-icon
                            v-if="modelHasRole.business != null"
                            @click.stop.prevent="
                              selectBusinessRole(modelHasRole, settings_route)
                            "
                            name="CogIcon"
                            class="
                              h-5
                              w-5
                              hover:text-gray-500
                              cursor-pointer
                              flex-none
                              text-gray-600
                            "
                          />
                        </div>
                      </div>
                      <hr class="w-full border-gray-200" />
                    </app-button-dropdown-item>
                  </template>

                  <!-- admin -->
                  <template v-if="hasGroupAdmin()">
                    <p
                      class="
                        bg-primary
                        text-white text-sm
                        font-normal
                        px-4
                        py-2
                      "
                    >
                      {{ $t("layout.header.administration") }}
                    </p>
                    <app-button-dropdown-item
                      v-for="(modelHasRole, index) in selectionRoles.filter(
                        (merchant) => merchant.business == null
                      )"
                      :key="modelHasRole.id"
                      :class="{ 'rounded-t-md': index == 0 }"
                      :active="isSelectedRole(modelHasRole)"
                      :disabled="isSelectedRole(modelHasRole)"
                      :fullWidth="false"
                      @onMenuClick="selectBusinessRole(modelHasRole)"
                      class="flex flex-col font-medium"
                    >
                      <div class="flex justify-between h-10 w-full px-3">
                        <span class="flex-none w-8 h-full flex items-center">
                          <div v-if="modelHasRole.business">
                            <app-image
                              v-if="getBusinessIcon(modelHasRole.business)"
                              :src="getBusinessIcon(modelHasRole.business)"
                              class="rounded-full w-5 h-5 flex-none"
                              spinnerSize="3px"
                            />

                            <div
                              v-else-if="getBusinessLogo(modelHasRole.business)"
                              class="
                                rounded-full
                                relative
                                overflow-hidden
                                w-5
                                h-5
                                bg-white
                              "
                            >
                              <img
                                :src="getBusinessLogo(modelHasRole.business)"
                                class="w-auto h-full"
                              />
                            </div>
                            <app-icon
                              v-else
                              name="BriefcaseIcon"
                              class="h-5 w-5 flex-none"
                            />
                          </div>
                          <app-icon v-else name="UserIcon" class="h-5 w-5" />
                        </span>
                        <div
                          class="
                            flex-grow
                            h-full
                            flex
                            items-center
                            space-x-1
                            truncate
                          "
                        >
                          <p class="truncate ...">
                            {{ getDisplayRoleName(modelHasRole) }}
                          </p>
                          <sup
                            class="
                              p-1
                              w-auto
                              rounded-md
                              text-center text-xxs
                              bg-success-badge
                              text-green-900
                              border border-green-100
                            "
                            v-if="checkIfMasterBusiness(modelHasRole)"
                          >
                            {{ $t("user.detail.master_business") }}
                          </sup>
                        </div>
                        <div
                          class="
                            flex-none flex flex-inline
                            h-full
                            items-center
                            w-10
                          "
                        >
                          <app-icon-loading
                            v-if="
                              select_default_loading &&
                              select_default_role.id == modelHasRole.id
                            "
                            customClass="w-5 h-5 text-primary"
                          />
                          <app-icon
                            v-else-if="this.selectionRoles?.length > 1"
                            @click.stop.prevent="
                              assignDefaultRole(modelHasRole)
                            "
                            name="StarIcon"
                            class="
                              h-5
                              w-5
                              text-gray-300
                              hover:text-gray-500
                              cursor-pointer
                              flex-none
                            "
                            :class="{
                              'text-primary hover:text-primary':
                                modelHasRole.default,
                            }"
                          />
                          <app-icon
                            v-if="modelHasRole.business != null"
                            @click.stop.prevent="
                              selectBusinessRole(modelHasRole, settings_route)
                            "
                            name="CogIcon"
                            class="
                              h-5
                              w-5
                              hover:text-gray-500
                              cursor-pointer
                              flex-none
                              text-gray-600
                            "
                          />
                        </div>
                      </div>
                      <hr class="w-full border-gray-200" />
                    </app-button-dropdown-item>
                  </template>

                  <!-- add business -->
                  <app-button-dropdown-item
                    class="
                      flex
                      py-3
                      px-4
                      w-full
                      text-sm
                      space-x-2
                      font-medium
                      items-center
                      rounded-b-md
                      justify-between
                      text-primary
                    "
                    :class="{ 'rounded-t-md': totalBusiness == 0 }"
                    @onMenuClick="createNewBusiness"
                  >
                    <p class="antialiased">
                      {{ $t("dashboard.add_new_business") }}
                      {{ "(" + totalBusiness + "/" + user.max_business + ")" }}
                    </p>
                    <app-icon
                      name="PlusCircleIcon"
                      class="h-5 w-5 sr-onlymr-3"
                    />
                  </app-button-dropdown-item>
                </template>
              </app-button-dropdown>

              <!-- business balance -->
              <app-button-outline
                class="h-10 space-x-2 w-full justify-between"
                @click="routeToPath('/balance/overview/index')"
                v-if="business.business_status_id == BUSINESS_STATUS.APPROVED"
              >
                <div
                  class="
                    place-items-start
                    flex flex-col
                    text-gray-700
                    font-semibold
                    antialiased
                    text-xs
                  "
                >
                  <span class="text-gray-700">
                    {{ $t("wallet.account_balance") }}
                  </span>
                  <span class="text-primary">
                    {{
                      $formats.currency(
                        accountBalance.currency,
                        accountBalance.account_balance ?? 0.0
                      )
                    }}
                  </span>
                </div>
                <app-icon name="ChevronRightIcon" class="w-4 h-4" />
              </app-button-outline>
            </div>
            <div class="md:flex-none flex-auto" />
            <div class="sm:flex space-x-5">
              
              <!--tawkto-->
              <button>
                <a :href="supportLink" target="_blank" class="text-black">
                  <app-text-tooltip :text="$t('general.contact_support')">
                    <app-icon-outline
                      name="SupportIcon"
                      class="h-6 w-6 text-gray-500"
                    />
                  </app-text-tooltip>
                </a>
              </button>

              <!-- dropdown notification -->
              <app-button-dropdown
                width="w-72 sm:w-96"
                :showArrow="true"
                class="relative"
                offsetArrow="3"
              >
                <app-icon name="BellIcon" class="h-6 w-6 text-gray-500" />
                <span
                  class="
                    h-2.5
                    w-2.5
                    absolute
                    top-0
                    right-0
                    bg-red-500
                    rounded-full
                    border border-white
                  "
                  v-if="notificationUnread > 0"
                />
                <template #menu-items>
                  <list-notification-items :limit="max_notification" />
                  <router-link :to="{ name: 'notifications' }">
                    <p
                      class="
                        p-3
                        cursor-pointer
                        hover:bg-gray-100
                        text-sm text-center text-primary
                      "
                    >
                      {{ $t("general.view_all") }}
                    </p>
                  </router-link>
                </template>
              </app-button-dropdown>

              <!-- dropdown profile -->
              <app-button-dropdown :showArrow="true" offsetArrow="3">
                <app-icon name="UserCircleIcon" class="h-6 w-6 text-gray-500" />

                <template #menu-header>
                  <p class="text-md font-medium">{{ user?.name ?? "-" }}</p>
                  <p class="text-xs">{{ user?.email ?? "-" }}</p>
                </template>

                <template #menu-items>
                  <!-- <div
                    v-if="user.referral_code"
                    class="p-3 flex w-full text-sm items-center text-gray-500"
                  >
                    {{
                      $t("layout.header.referral_code") +
                      user.referral_code +
                      "  "
                    }}
                    <app-icon-outline
                      name="DocumentDuplicateIcon"
                      class="ml-1 w-4 text-gray-400 cursor-pointer"
                      @click="copyCode()"
                    />
                  </div> -->
                  <app-button-menu
                    class="
                      p-3
                      flex
                      w-full
                      text-sm
                      items-center
                      hover:bg-gray-100 hover:text-primary
                    "
                    :fullWidth="true"
                    :rounded="false"
                    v-for="item in user_menu_items"
                    :key="item.route_name"
                    :disable="false"
                    @onMenuClick="routeTo(item.route_name)"
                  >
                    <app-icon :name="item.icon" class="w-5 h-5 mr-3" />
                    {{ item.title }}
                  </app-button-menu>
                </template>
              </app-button-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Role from "@/utils/const/role";
import Group from "@/utils/const/group";
import BUSINESS_STATUS from "@/utils/const/business_status";
import listNotificationItems from "../../pages/main/notification/items/list-notification-items.vue";
export default {
  components: { listNotificationItems },
  mounted() {
    this.init();
  },
  data() {
    return {
      Role: Role,
      Group: Group,
      max_notification: 5,
      user_menu_items: [],
      create_menu_items: [],
      business_route: "business",
      settings_route: "business.settings",
      select_loading: null,
      select_default_role: null,
      select_default_loading: null,
      select_tab_notification: "all",
      BUSINESS_STATUS: BUSINESS_STATUS,
      supportLink:
        "https://tawk.to/chat/" +
        process.env.VUE_APP_TAWKTO_PROPERTY_ID +
        "/" +
        process.env.VUE_APP_TAWKTO_WIDGET_ID,
    };
  },
  computed: {
    user() {
      return this.$store.getters["authStore/user"];
    },
    accountBalance() {
      return this.$store.getters["walletStore/accountBalance"];
    },
    selectionRoles() {
      return this.$lodash.sortBy(
        this.$store.getters["authStore/selectionRoles"] ?? [],
        (selectionRole) => selectionRole.business
      );
    },
    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },
    business() {
      return this.$store.getters["businessStore/business"];
    },
    totalBusiness() {
      return (
        this.selectionRoles?.filter(function (modelHasRole) {
          return (
            modelHasRole.business != null &&
            modelHasRole.role_id == Role.BUSINESS_OWNER
          );
        })?.length ?? 0
      );
    },
    notificationUnread() {
      return this.$store.getters["userStore/notificationUnread"];
    },
  },
  beforeCreate() {
    this.$store.dispatch("businessStore/getBusinessFromSelectedRoleBusinessId");
    this.$store.dispatch("walletStore/getAccountBalance");
    this.$store.dispatch("userStore/notificationUnread");
  },
  methods: {
    getDisplayRoleName(modelHasRole) {
      if (modelHasRole == null || modelHasRole.id == undefined)
        return this.totalBusiness > 0 || this.selectionRoles?.length > 0
          ? this.$t("general.please_select")
          : this.$t("dashboard.add_business");

      return modelHasRole.business != null
        ? modelHasRole.business.name
        : this.$lodash.startCase(modelHasRole.role.name);
    },
    checkIfMasterBusiness(modelHasRole) {
      if (modelHasRole == null || modelHasRole.role_id == undefined)
        return false;

      return (
        modelHasRole.role_id == Role.MASTER_BUSINESS_OWNER ||
        modelHasRole.role_id == Role.MASTER_BUSINESS_ADMIN ||
        modelHasRole.role_id == Role.MASTER_BUSINESS_MANAGER ||
        modelHasRole.role_id == Role.MASTER_BUSINESS_DEVELOPER ||
        modelHasRole.role_id == Role.MASTER_BUSINESS_SUPPORT
      );
    },
    isSelectedRole(modelHasRole) {
      return this.selectedRole?.id == modelHasRole?.id;
    },
    selectBusinessRole(modelHasRole, redirectTo = null) {
      let self = this;
      self.select_loading = true;
      setTimeout(() => {
        self.$store.dispatch("authStore/setSelectedRole", modelHasRole.id);
        self.$store
          .dispatch("businessStore/getBusinessFromSelectedRoleBusinessId")
          .then(() => {
            self.select_loading = false;
          });

        self.$store.dispatch("paymentStore/getTotalPaid");
        self.$store.dispatch("authStore/refreshUser");

        if (redirectTo == null) {
          self.$store.dispatch("businessStore/removeTestMode");
          window.location.href = "/";
          return;
        }

        this.$router.push({ name: redirectTo });
      }, 1000);
    },
    createNewBusiness() {
      window.location.href = this.$router.resolve({
        name: this.business_route,
      }).href;
      this.$store.dispatch("authStore/clearSelectedRole");
      this.$store.dispatch("businessStore/removeTestMode");
    },
    routeTo(name) {
      this.$router.push({
        name: name,
      });
    },
    routeToPath(path, queryParams) {
      this.$router.push({
        path: path,
        query: queryParams,
      });
      if (this.$router.path == path) {
        this.$forceUpdate();
        return;
      }
    },
    async assignDefaultRole(modelHasRole) {
      this.select_default_role = modelHasRole;
      this.select_default_loading = true;
      let response = await this.$store.dispatch(
        "authStore/setSelectedDefaultRole",
        {
          modelHasRole: modelHasRole,
          default: !modelHasRole.default,
        }
      );

      if (response.data) {
        this.$store.dispatch("authStore/refreshUser");
      }
      this.select_default_loading = false;
    },
    getBusinessLogo(business) {
      return business.media?.find((media) => media.collection_name == "logo")
        ?.original_url;
    },
    getBusinessIcon(business) {
      return business.media?.find((media) => media.collection_name == "icon")
        ?.original_url;
    },
    hasGroupMasterMerchant() {
      return (
        this.selectionRoles?.filter(function (merchant) {
          return (
            merchant.business != null &&
            merchant.group_id == Group.MASTER_MERCHANT
          );
        })?.length ?? false
      );
    },
    hasGroupAdmin() {
      return (
        this.selectionRoles?.filter(function (modelHasRole) {
          return (
            modelHasRole.business == null ||
            modelHasRole.group_id == Group.ADMIN
          );
        })?.length ?? false
      );
    },
    copyCode() {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(this.user.referral_code);

      this.$notify(
        {
          group: "success",
          title: this.$t("business.package.copy_code"),
        },
        5000
      );
    },

    init() {
      this.create_menu_items = [
        { title: this.$t("layout.header.invoice"), route_name: null },
        { title: this.$t("layout.header.subscription"), route_name: null },
        { title: this.$t("layout.header.payment_link"), route_name: null },
        { title: this.$t("layout.header.payment"), route_name: null },
      ];

      this.user_menu_items = [
        // {
        //   title: this.$t("layout.header.edit_profile"),
        //   route_name: null,
        //   icon: "CogIcon",
        // },
        // {
        //   title: this.$t("layout.header.settings"),
        //   route_name: null,
        //   icon: "CogIcon",
        // },
        {
          title: this.$t("layout.header.logout"),
          route_name: "logout",
          icon: "LogoutIcon",
        },
      ];
    },
  },
};
</script>
