import client from "@/data/base-client";
import WithAuthRepository from "./with-auth-repository";

export default class IntegrationRepository extends WithAuthRepository {
  constructor() {
    super();
  }

  async getIntegration(business_id) {
    const response = await client.get(
      "/integrations/businesses/" + business_id + "/integration",
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async resetApiKey(business_id, integration_id) {
    const response = await client.post(
      "/integrations/businesses/" +
        business_id +
        "/integrations/" +
        integration_id +
        "/reset-api-key",
      [],
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async resetSignatureKey(business_id, integration_id) {
    const response = await client.post(
      "/integrations/businesses/" +
        business_id +
        "/integrations/" +
        integration_id +
        "/reset-signature-key",
      [],
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getWebhookEventNames(business_id) {
    const response = await client.get(
      "/integrations/businesses/" + business_id + "/webhook-event-names",
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }
  
  async getWebhookEventNamesForMasterBusiness(business_id) {
    const response = await client.get(
      "/integrations/master-businesses/" + business_id + "/webhook-event-names",
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async createOrUpdate(business_id, integration_id, webhook) {
    if (webhook.name != null && webhook._id == null) {
      webhook.name = webhook.name.name;
    } else if (webhook._id != null) {
      webhook.name = null;
    }

    const to_webhook = this.$lodash.omitBy(webhook, this.$lodash.isNil);

    const response =
      to_webhook._id == null
        ? await client.post(
            "/integrations/businesses/" +
              business_id +
              "/integrations/" +
              integration_id +
              "/webhook-events",
            to_webhook,
            this.getToken()
          )
        : await client.put(
            "/integrations/businesses/" +
              business_id +
              "/integrations/" +
              integration_id +
              "/webhook-events/" +
              to_webhook._id,
            to_webhook,
            this.getToken()
          );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async webhookIndex(business_id, integration_id, queryParams) {
    const response = await client.get(
      "/integrations/businesses/" +
        business_id +
        "/integrations/" +
        integration_id +
        "/webhook-events" + queryParams,
        this.getToken(),
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async webhookIndexForMasterBusiness(business_id, integration_id, queryParams) {
    const response = await client.get(
      "/integrations/master-businesses/" +
        business_id +
        "/integrations/" +
        integration_id +
        "/webhook-events" + queryParams,
        this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async webhookDetailsIndex(
    business_id,
    integration_id,
    webhook_event_id,
    queryParams
  ) {
    const response = await client.get(
      "/integrations/businesses/" +
        business_id +
        "/integrations/" +
        integration_id +
        "/webhook-events/" +
        webhook_event_id +
        "/webhook-event-logs" + queryParams,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async webhookRetry(business_id, integration_id, webhook_event_id, webhook_event_log_id) {
    const response = await client.post(
      "/integrations/businesses/" +
        business_id +
        "/integrations/" +
        integration_id +
        "/webhook-events/" +
        webhook_event_id +
        "/webhook-event-logs/" + 
        webhook_event_log_id +
        "/resend-webhook",
      {},
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async generateTestModePaymentProvider(businessId) {
    const response = await client.post(
      "/internal/generate-test-mode-payment-provider",
      { business_id: businessId },
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return response.data;
  }
}
