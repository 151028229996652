<template>
  <app-full-modal
    :title="$t('invoice.detail.invoice_no', { no: invoice.invoice_no })"
  >
    <template v-slot:outside>
      <app-modal :show="showConfirmation" @close="closeConfirmation">
        <template #title>
          <span class="capitalize">{{
            $t("invoice.create.resend_invoice_title")
          }}</span>
        </template>
        <template #body>
          <div class="flex flex-col space-y-3 text-sm text-gray-600">
            <i18n-t keypath="invoice.create.resend_invoice_description" tag="p">
              <template v-slot:amount>
                <span class="font-bold">
                  {{
                    $formats.currency(invoice.currency, invoice.total_amount)
                  }}
                </span>
              </template>
              <template v-slot:customer>
                <span class="font-bold">{{ invoice.customer?.name }}</span>
              </template>
            </i18n-t>
          </div>
        </template>
        <template #action>
          <app-button
            :showf70Icon="false"
            :loading="loading"
            width=""
            class="ml-3"
            @click="resendInvoice"
          >
            {{ $t("invoice.create.send_invoice") }}
          </app-button>
        </template>
      </app-modal>
      <app-modal
        :show="showDeleteConfirmation"
        @close="showDeleteConfirmation = false"
      >
        <template v-slot:title>{{
          $t("invoice.action.delete_invoice")
        }}</template>
        <template v-slot:body>
          <p class="text-sm text-gray-500">
            {{ $t("invoice.action.delete_invoice_desc") }}
          </p>
        </template>
        <template v-slot:action>
          <app-button
            @onClick="deleteInvoice"
            :showf70Icon="false"
            :primary="false"
            type="button"
            class="
              sm:ml-3 sm:w-auto
              bg-error
              hover:bg-error
              text-white
              outline-none
            "
          >
            {{ $t("general.delete") }}
          </app-button>
        </template>
      </app-modal>
      <app-modal
        :show="showModalChangeStatus"
        @close="showModalChangeStatus = false"
      >
        <template v-slot:title>{{
          $t("invoice.detail.change_status")
        }}</template>
        <template v-slot:body>
          <app-form-select
            class="w-1/4 mb-4"
            :placeholder="$t('invoice.detail.select_status')"
            :datas="getStatus"
            :allowFilter="false"
            v-model="newStatus"
          />
          <app-form-textarea
            id="invoice.change_status"
            name="invoice.change_status"
            type="text"
            class="w-full"
            :labelText="$t('invoice.detail.change_status_reason')"
            v-model="changeReason"
          />
        </template>
        <template v-slot:action>
          <app-button
            @onClick="changeStatus"
            :showf70Icon="false"
            :loading="loading"
            :primary="false"
            type="button"
            class="
              sm:ml-3 sm:w-auto
              bg-primary
              hover:bg-error
              text-white
              outline-none
            "
          >
            {{ $t("invoice.action.update_status") }}
          </app-button>
        </template>
      </app-modal>
    </template>
    <template v-slot:body>
      <div class="flex flex-col pt-6 space-y-7">
        <div class="flex flex-row space-x-6 px-5">
          <div v-for="{ id, value } in amounts" :key="id">
            <p class="text-gray-400 text-xs mb-2">
              {{ $t(`invoice.detail.${id}`) }}
            </p>
            <p class="text-lg font-bold text-gray-700">
              {{ $formats.currency(invoice.currency, value) }}
            </p>
          </div>
          <div>
            <p class="text-gray-400 text-xs mb-2">
              {{ $t("invoice.detail.status_label") }}
            </p>
            <div class="flex flex-row space-x-10">
              <div
                class="
                  px-3
                  py-1
                  text-xs
                  rounded-md
                  flex
                  place-items-center
                  justify-center
                "
                :class="{
                  'bg-gray-200 text-gray-600':
                    invoice.status == INVOICE_STATUS.CREATED,
                  'bg-blue-100 text-blue-900':
                    invoice.status == INVOICE_STATUS.OPEN,
                  'bg-red-400 text-white':
                    invoice.status == INVOICE_STATUS.OVERDUE,
                  'bg-green-400 text-white':
                    invoice.status == INVOICE_STATUS.PAID,
                  'bg-blue-300 text-blue-600':
                    invoice.status == INVOICE_STATUS.VOID,
                }"
              >
                <p>{{ $t(`invoice.detail.status.${invoice.status}`) }}</p>
              </div>
              <app-button-outline
                v-if="invoice.status === INVOICE_STATUS.PAID"
                @click="sendReceipt"
                padding="px-4 py-1"
              >
                {{ $t("invoice.action.send_receipt") }}
              </app-button-outline>
              <app-button-dropdown v-else>
                <app-button-outline padding="px-4 py-1">
                  {{ $t("general.actions") }}
                  <app-icon name="ChevronDownIcon" class="w-5 h-5 ml-2" />
                </app-button-outline>
                <template #menu-items>
                  <app-button-dropdown-item
                    v-for="item in getActionItems()"
                    :key="item.key"
                    :disabled="item.disable"
                    :show="item.show"
                    class="p-3"
                    @click.stop="handleActionItemClick(item)"
                  >
                    {{ item.title }}
                  </app-button-dropdown-item>
                </template>
              </app-button-dropdown>
            </div>
          </div>
        </div>
        <div class="flex flex-row divide-x px-5">
          <div
            v-for="({ id, value }, idx) in dates.filter(
              (date) => date.id != null
            )"
            :key="id"
            :class="['pr-6', { 'pl-4': idx > 0 }]"
          >
            <p class="text-gray-400 text-xs mb-2">
              {{
                id == "payment_due" && isOverdue
                  ? $t(`invoice.detail.status.overdue_short`)
                  : $t(`invoice.detail.${id}`)
              }}
            </p>
            <p class="text-sm whitespace-pre date">
              {{ value }}
            </p>
          </div>
        </div>
        <div
          v-if="invoice.internal_memo"
          class="border rounded-md py-4 px-3 mx-5"
        >
          <p class="text-gray-400 text-xs mb-2">
            {{ $t("invoice.create.internal_memo") }}
          </p>
          <p class="text-sm">
            {{ invoice.internal_memo }}
          </p>
        </div>
        <app-invoice-history />
        <div>
          <p class="px-5 py-2 font-medium">
            {{ $t("invoice.detail.invoice_details") }}
          </p>
          <hr />
          <div class="p-5 flex flex-col space-y-2 text-sm">
            <div
              v-for="{ id, value } in invoiceDetails.filter(
                (invoiceDetail) => invoiceDetail.id != null
              )"
              :key="id"
              class="flex flex-row"
            >
              <p class="text-gray-400 w-1/5">{{ $t(id) }}</p>
              <p>{{ value || "-" }}</p>
            </div>
            <div
              v-for="({ key, value }, idx) in invoice.custom_attributes"
              :key="idx"
              class="flex flex-row"
            >
              <p class="text-gray-400 w-1/5 capitalize">
                {{ key }}
              </p>
              <p class="capitalize">{{ value || "-" }}</p>
            </div>
          </div>
        </div>
        <div>
          <p class="px-5 py-2 font-medium">
            {{ $t("invoice.detail.customer_details") }}
          </p>
          <hr />
          <div class="p-5 flex flex-col space-y-2 text-sm">
            <div
              v-for="{ id, value } in customerDetails"
              :key="id"
              class="flex flex-row"
            >
              <p class="text-gray-400 w-1/5">{{ $t(id) }}</p>
              <p>{{ value || "-" }}</p>
            </div>
          </div>
        </div>
        <div>
          <p class="px-5 py-2 font-medium">
            {{ $t("invoice.action.product_list") }}
          </p>
          <hr />
          <div class="p-5 overflow-x-auto">
            <table class="min-w-full">
              <thead class="bg-white text-gray-400">
                <tr>
                  <td class="py-auto">
                    {{ $t("invoice.create.product_image") }}
                  </td>
                  <td class="py-1">{{ $t("invoice.detail.description") }}</td>
                  <td class="py-1">{{ $t("invoice.create.product_qty") }}</td>
                  <td class="py-1">{{ $t("invoice.create.tax") }}</td>
                  <td class="py-1">
                    {{ $t("invoice.create.product_amount") }}
                  </td>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-divider-gray">
                <tr v-for="item in invoice.items" :key="item.product_id">
                  <td>
                    <app-image
                      v-if="getProductPhoto(item)"
                      :src="getProductPhoto(item)"
                      class="rounded-md flex-none w-12"
                    />
                    <img
                      v-else
                      class="w-12"
                      src="@/assets/image/default_product_image.svg"
                    />
                  </td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.quantity }}</td>
                  <td v-if="item.tax_rate">
                    {{ $t("invoice.create.tax") }} {{ `${item.tax_rate}%` }}
                    {{
                      item.tax_type === "inclusive"
                        ? `${$t(`tax.types.${item.tax_type}_short`)}`
                        : ""
                    }}
                  </td>
                  <td v-else>
                    {{ "-" }}
                  </td>
                  <td>{{ $formats.currency(item.currency, item.amount) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </app-full-modal>
</template>

<style scoped>
.date {
  tab-size: 2em;
}
</style>

<script>
import MEDIA_CATEGORY from "@/utils/const/media_category";
import INVOICE_STATUS from "@/utils/const/invoice_status";

export default {
  emits: ["refresh"],
  data() {
    return {
      showDeleteConfirmation: false,
      showConfirmation: false,
      INVOICE_STATUS,
      showModalChangeStatus: false,
      newStatus: {},
      changeReason: "",
    };
  },
  computed: {
    loading() {
      return this.$store.getters["invoiceStore/loading"];
    },
    invoice() {
      return this.$store.getters["invoiceStore/invoice"];
    },
    customer() {
      return this.$store.getters["customerStore/customer"];
    },
    customerBillingAddress() {
      return this.$store.getters["customerStore/customerAddress"]("billing");
    },
    customerShippingAddress() {
      return this.$store.getters["customerStore/customerAddress"]("shipping");
    },
    amounts() {
      return [
        { id: "total_amount", value: this.invoice.total_amount || 0 },
        { id: "total_paid", value: this.invoice.paid || 0 },
        {
          id: "amount_due",
          value: (this.invoice.total_amount || 0) - (this.invoice.paid || 0),
        },
      ];
    },
    dueIn() {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      return this.$moment(this.invoice.payment_term_date, "YYYY-MM-DD").diff(
        now,
        "days"
      );
    },
    paymentDueInText() {
      if (this.dueIn == 0) {
        return this.$t("invoice.detail.due_in_today");
      }

      if (this.dueIn == 1) {
        return this.$t("invoice.detail.due_in_tomorrow");
      }

      return this.$t("invoice.detail.due_in_short", {
        n: Math.abs(this.dueIn),
      });
    },
    paymentTermInText() {
      if (this.isOverdue) {
        return this.$t("invoice.detail.overdue_in", {
          n: Math.abs(this.dueIn),
        });
      }

      if (this.dueIn == 0) {
        return this.$t("invoice.detail.due_term_in_today");
      }

      if (this.dueIn == 1) {
        return this.$t("invoice.detail.due_term_in_tomorrow");
      }

      return this.$t("invoice.detail.due_in", { n: this.dueIn });
    },
    dates() {
      return [
        {
          id: "created_date",
          value: this.$moment(this.invoice.created_at).format(
            "DD MMMM YYYY\th:mm:ss A"
          ),
        },
        {
          id: "due_date",
          value: this.invoice.payment_term_date
            ? this.$moment(this.invoice.payment_term_date, "YYYY-MM-DD").format(
                "DD MMMM YYYY"
              )
            : "-",
        },
        this.invoice.status != INVOICE_STATUS.PAID
          ? {
              id: "payment_due",
              value: this.invoice.payment_term_date
                ? this.paymentDueInText
                : "-",
            }
          : {},
      ];
    },
    invoiceDetails() {
      return [
        {
          id: "invoice.detail.status_label",
          value: this.$t(`invoice.detail.status.${this.invoice.status}_short`),
        },
        this.invoice.status != INVOICE_STATUS.PAID
          ? {
              id: "invoice.detail.payment_term",
              value: this.invoice.payment_term_date
                ? this.paymentTermInText
                : "-",
            }
          : {},
        {
          id: "invoice.detail.payment_type",
          value: this.$t(`invoice.create.types.${this.invoice.type}`),
        },
        {
          id: "invoice.detail.amount",
          value: this.$formats.currency(
            this.invoice.currency,
            this.invoice.total_amount
          ),
        },
        {
          id: "invoice.create.tax",
          value: this.invoice.tax_type
            ? this.$t(`tax.types.${this.invoice.tax_type}`)
            : "",
        },
        { id: "invoice.create.memo", value: this.invoice.memo },
      ];
    },
    customerDetails() {
      return [
        { id: "invoice.detail.customer_name", value: this.customer.name },
        { id: "invoice.detail.customer_email", value: this.customer.email },
        {
          id: "invoice.detail.customer_billing_address",
          value: this.customerBillingAddress,
        },
        {
          id: "invoice.detail.customer_shipping_address",
          value: this.customerShippingAddress,
        },
        { id: "invoice.detail.customer_phone", value: this.customer.phone_no },
      ];
    },
    isOverdue() {
      return this.dueIn < 0;
    },
    getStatus() {
      return [
        { id: INVOICE_STATUS.CREATED, name: INVOICE_STATUS.CREATED },
        { id: INVOICE_STATUS.OPEN, name: INVOICE_STATUS.OPEN },
        { id: INVOICE_STATUS.OVERDUE, name: INVOICE_STATUS.OVERDUE },
        { id: INVOICE_STATUS.PAID, name: INVOICE_STATUS.PAID },
        {
          id: INVOICE_STATUS.UNCOLLECTIBLE,
          name: INVOICE_STATUS.UNCOLLECTIBLE,
        },
      ];
    },
  },
  watch: {
    invoice() {
      if (this.invoice._id || this.invoice.id) {
        this.newStatus = {
          id: this.invoice?.status,
          name: this.invoice?.status,
        };
      }
    },
  },
  methods: {
    getProductPhoto(product) {
      const url = product?.media?.find(
        (media) => media.collection_name == MEDIA_CATEGORY.PHOTO
      )?.original_url;
      return url && url.startsWith("/")
        ? `${this.$store.$backendURL}${url}`
        : url;
    },
    async deleteInvoice() {
      console.log(this.invoice);
      const result = await this.$store.dispatch(
        "invoiceStore/deleteInvoice",
        this.invoice
      );
      if (!this.$lodash.isNil(result)) {
        this.$emit("refresh");
        this.$emit("close");
      }
      this.showDeleteConfirmation = false;
    },
    displayDeleteConfirmation() {
      this.showDeleteConfirmation = true;
    },
    displayEditInvoiceForm(data) {
      return this.$router.push(`/invoice/edit/${data._id}`);
    },
    sendReceipt() {
      console.log("@TODO: trigger send receipt");
    },
    getActionItems() {
      const { status } = this.invoice;
      return [
        {
          key: "edit",
          title: this.$t("invoice.action.edit_invoice"),
          show: status === INVOICE_STATUS.CREATED,
        },
        {
          key: "delete",
          title: this.$t("invoice.action.delete_invoice"),
          show: status === INVOICE_STATUS.CREATED,
        },
        {
          key: "resend",
          title: this.$t("invoice.action.resend_invoice"),
          show: status !== INVOICE_STATUS.CREATED,
        },
        {
          key: "change_status",
          title: this.$t("invoice.action.change_status"),
          show: status !== INVOICE_STATUS.CREATED,
        },
      ];
    },
    handleActionItemClick(menu) {
      if (menu.key === "edit") {
        this.displayEditInvoiceForm(this.invoice);
      }

      if (menu.key === "delete") {
        return this.displayDeleteConfirmation();
      }

      if (menu.key === "resend") {
        this.confirmSending();
      }

      if (menu.key === "change_status") {
        this.showModalChangeStatus = true;
      }
    },
    confirmSending() {
      this.showConfirmation = true;
    },
    closeConfirmation() {
      this.showConfirmation = false;
    },
    async resendInvoice() {
      this.invoice.status = INVOICE_STATUS.OPEN;

      const result = await this.$store.dispatch(
        "invoiceStore/createOrUpdateInvoice",
        this.invoice
      );

      if (result != null) {
        this.$notify(
          {
            group: "success",
            title: "Sent!",
          },
          5000
        );
      }
      this.closeConfirmation();
    },
    async changeStatus() {
      this.invoice.change_status_reasons = this.changeReason;
      this.invoice.status = this.newStatus.id;

      const result = await this.$store.dispatch(
        "invoiceStore/createOrUpdateInvoice",
        this.invoice
      );

      if (!this.$lodash.isNil(result)) {
        this.$emit("refresh");
        this.$emit("close");
      }
      this.showModalChangeStatus = false;
    },
  },
};
</script>
